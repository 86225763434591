import React, { FC, useMemo, useCallback, useRef, useEffect, useState } from 'react';
import { BryntumGantt, BryntumGanttProps } from '@bryntum/gantt-react-thin';
import { Splitter, GridColumnConfig, Model, DurationUnitShort } from '@bryntum/core-thin';
import { ColumnStore } from '@bryntum/grid-thin';
import { CalendarModel } from '@bryntum/gantt-thin';
import '@bryntum/scheduler-thin/lib/feature/DependencyEdit';
import '@bryntum/scheduler-thin/lib/feature/TimeRanges';
import { differenceInBusinessDays, isBefore, isSameDay } from 'date-fns';
import moment from 'moment';
import zipcelx from 'zipcelx';
import { debounce, isArray, isEqual, isNumber, orderBy, uniq } from 'lodash';
import {
  ConfirmLeaveCallback,
  GanttDisplayModel,
  PulseTimelineGanttProps,
  TimelineMarker,
} from 'components/pulse-timeline/pulse-timeline-types';
import { PulseTimelineTaskModel } from 'components/pulse-timeline/model/pulse-timeline-model';
import { Filters } from 'components/pulse-filter/slice';
import PulseTimelineGanttToolbar from './pulse-timeline-gantt-toolbar';
import PulseTimelineGanttTaskDeleteModal from './pulse-timeline-gantt-task-delete-modal';
import TimelinePhasesFlyout from './pulse-timeline-gantt-phases-flyout';
import OverrideBaselineModal from '../BaselineModal/override-baseline-modal';
import NoBaselineModal from '../BaselineModal/no-baseline-modal';

import { presets } from './pulse-timeline-gantt-presets';
import {
  beforeUpdate,
  getJWTToken,
  filterToParams,
  getPDFExportURL,
  taskMenuBeforeShow,
  PulseTimelineApplyTemplateHelper,
  updateSessionStorageGanttDisplayConfig,
  getStatusString,
  isOpenViaPublicLinks,
} from './pulse-timeline-helpers';
import { registerIdColumn } from './column-helpers/pulse-timeline-gantt-column-id';
import { registerStatusColumn } from './column-helpers/pulse-timeline-gantt-column-status';
import TimelineColorField, { DEFAULT_TIMELINE_COLORS } from './widgets/ColorField';
import {
  DEFAULT_COLUMNS,
  SESSION_STORAGE_COLUMNS_CONFIGS_KEY,
  SESSION_STORAGE_GANTT_DISPLAY_CONFIG,
  SESSION_STORAGE_GANTT_ZOOM_LEVEL,
  SESSION_STORAGE_SUB_GRID_CONFIGS_VISIBLE,
  SESSION_STORAGE_SUB_GRID_WIDTH_CONFIGS_KEY,
} from './column-helpers';
import './pulse-timeline-gantt.css';
import styles from './pulse-timeline-gantt.module.scss';
import ganttMockup from '../../mock/gantt-data.json';
import ImportProjectFileModal from '../ImportFileModal/import-project-file-modal';
import UnSavedNotificationModal from '../UnsavedNotificationModal/unsaved-notification-modal';
import NonWorkingDaysModal from '../NonWorkingDaysModal/non-working-days-modal';
import { NonWorkingDaysModel } from '../NonWorkingDaysModal/non-working-days-modal-types';
import MsProjectImportHelper from '../ImportFileModal/ms-project-import-helper';
import { CustomCycleResolutionPopup } from 'components/pulse-timeline/model/pulse-cycle-resolution-popup-model';
import PulseTaskImportModal, {
  ImportTaskData,
  ImportTaskOptions,
} from '../TimelineImportTaskModal/timeline-import-task-modal';
import clsx from 'clsx';
import { BASE_URL } from '../NonWorkingDaysModal/components/helpers';
import { PulseTimelineGanttLinkedTaskTab } from './pulse-timeline-gantt-linked-task-tab';
import { v2Endpoint } from 'pulse-api/base';
import { ViewPreset } from '@bryntum/scheduler-thin';

const currentUserTimeFormat = window.pulse?.config?.user?.dateFormat
  ? `${window.pulse?.config?.user?.dateFormat} hh:mm:ss`
  : 'MM/DD/YYYY hh:mm:ss';

export const GANTT_EXPORT_FILE_TYPES = {
  FILE_PDF: 'PDF',
  FILE_MSP: 'MSP',
  FILE_EXCEL: 'EXCEL',
};

// Default Bryntum Gantt locked sub grid width is 1089
const DEFAULT_SUB_GRID_WIDTH = '320';

const DEFAULT_CONSTRAINT_TYPE = 'startnoearlierthan';

const ganttZoomLevel = Number(localStorage.getItem(SESSION_STORAGE_GANTT_ZOOM_LEVEL));

// Default value of timeline item when add new item by enter at end or click button
const DEFAULT_TIMELINE_ITEM = {
  name: 'New item',
  startDate: '',
  endDate: '',
  status: 'New',
  color: 'teal',
  constraintType: DEFAULT_CONSTRAINT_TYPE,
  progress: 0,
  percentDone: 0,
};

let isInitialLoad = true;

// Storage flyout open for destroy callback
let timelinePhasesFlyout: TimelinePhasesFlyout | undefined;

// Register Task editor's widgets
// Remove because it already added on v5.6.4
TimelineColorField.initClass();

// Format filname for export files along with timestmp
const getFileNameWithTimestamp = (timelineName: string) => {
  return `${timelineName}_${moment().format(currentUserTimeFormat)}`;
};

// format to unit short hand
const UNIT_DURATION_DEFAULT: Record<string, DurationUnitShort> = {
  week: 'w',
  year: 'y',
  day: 'd',
  month: 'M',
  quarter: 'q',
};
const getShortNameOfUnit = (unit: string): DurationUnitShort => {
  // we have getShortNameOfUnit from DateHelper , but wrong unit short name
  return UNIT_DURATION_DEFAULT[unit];
};
// We need to use intervals as "not a store". Keeping this as store (bryntum changes) make the calendar nonworking time doesn't work with our logic.
class TimelineGanttCalendarModel extends CalendarModel {
  static get fields() {
    return [{ name: 'intervals', subStore: false }];
  }
}

const PulseTimelineGantt: FC<PulseTimelineGanttProps> = props => {
  const {
    ganttHeight = 'calc(100vh - 73px)',
    userPermissions,
    project,
    actions: { loadUrl, syncUrl },
    showMock = false,
    templates = [],
    timeline,
    rollup,
    onOpenPromoteToMasterModal,
    onOpenDeleteTimelineModal,
    onOpenShareTimelineModal,
    onOpenEditTemplate,
    onOpenSaveAsTemplate,
    onOpenEditTimelineModal,
    onHandleImportData,
    gridTimelinesPhaseProps,
  } = props || {};

  const projectTitle = project?.jobtitle;
  const projectId = project?.jobid;
  const canEditTimeline = userPermissions?.editTimelines === 'y';

  // Enable Promote to Master if current timeline is not master timeline
  const canPromoteToMaster = !(timeline?.isMaster || false);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>();
  const [deleteRecords, setDeleteRecords] = useState<Array<any>>([]);
  const [selectedBaseline, setSelectedBaselines] = useState<number>();
  const [baselines, setBaselines] = useState<Array<number>>([]);
  const [currentBaselines, setCurrentBaselines] = useState<Array<number>>([]);
  const [showNoBaselineModal, setShowNoBaselineModal] = useState<boolean>(false);
  const [showNonWorkingDaysModal, setShowNonWorkingDaysModal] = useState<boolean>(false);
  const [showFileImporterModal, setShowFileImporterModal] = useState<boolean>();
  const [undoCount, setUndoCount] = useState<number>(0);
  const [redoCount, setRedoCount] = useState<number>(0);
  const [changeCount, setChangeCount] = useState<number>(0);
  const [zoomLevel, setZoomLevel] = useState<number>(ganttZoomLevel);
  const [timeRangeChangeCount, setTimeRangeChangeCount] = useState<number>(0);
  const [confirmLeave, setConfirmLeave] = useState<{
    isOpen: boolean;
    proceedCallback?: ConfirmLeaveCallback;
  }>({ isOpen: false });
  const [openTaskImportModal, setOpenTaskImportModal] = useState<boolean>(false);
  const hasNonworkingTimeChangesRef = useRef<any>(false);

  const weekendWorkingRef = useRef(false);
  const [shouldEnableSaveAsTemplate, setShouldEnableSaveAsTemplate] = useState<boolean>(false);
  const unlinkTasks = useRef<Array<{ timelineItemId: number; ticketId: number | string }>>([]);
  const ganttRef = useRef<BryntumGantt>(null);
  const linkedTaskRef = useRef<Record<string | number, Model>>({});
  const parentTaskRef = useRef<Array<string | number>>([]);

  const handleExpandAll = useCallback(() => {
    if (!ganttRef.current || isInitialLoad) {
      return;
    }
    const ganttInstance = ganttRef.current.instance;

    ganttInstance.expandAll();
  }, []);

  const handleCollapseAll = useCallback(() => {
    if (!ganttRef.current || isInitialLoad) {
      return;
    }
    const ganttInstance = ganttRef.current.instance;
    ganttInstance.collapseAll();
  }, []);

  const textColor = timeline?.isMaster ? 'white' : rollup ? 'white' : 'black';
  const backgroundColor = timeline?.isMaster ? '#66c6bf' : rollup ? '#b08dff' : 'white';
  const headerTpl = () => `
    <div id="top-row" style="margin-bottom: 60px;">
      <div class="uk-grid uk-match-height pulse__dark_nav uk-margin-remove uk-light">
        <div style="display: flex; align-items: center; color: white; background: rgba(0, 0, 0, 0.25); padding: 5px;">
          <img style='height: 40px !important;' src="${
            window?.pulse?.config?.urls?.pulse
          }images/pulse_logomark_transparent.png" alt="Pulse">
          <h2 style="margin: 10px;">
              <strong>Timelines</strong>
          </h2>
        </div>
        <div class="uk-width-expand">
          <div style="display: flex; column-gap: 10px; margin: 10px; align-items: center;">
        <a style="color: white; text-decoration: none;" href="" title="" uk-tooltip="" target="_blank" aria-expanded="false">
            <strong >${timeline?.timelineName}</strong>
          </a>
        <div style='margin: 5px;
            background-color: ${backgroundColor};
            color: ${textColor} !important;
            width: 90px;
            font-size: 10px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 12px;'>
            <span style='color: ${textColor} !important;'>${
    timeline?.isMaster ? 'Master' : rollup ? 'Rollup' : 'Scenario'
  }</span>
        </div>
        <div class="uk-width-auto uk-visible@m"></div>
      </div>
    </div>`;

  const handleZoomIn = useCallback(() => {
    if (!ganttRef.current || isInitialLoad) {
      return;
    }
    const ganttInstance = ganttRef.current.instance;
    ganttInstance.zoomIn();
    setZoomLevel(ganttInstance.zoomLevel);
    ganttInstance.fillTicks = ganttInstance.zoomLevel > 8;
    localStorage.setItem(SESSION_STORAGE_GANTT_ZOOM_LEVEL, ganttInstance.zoomLevel.toString());
  }, []);

  const handleZoomOut = useCallback(() => {
    if (!ganttRef.current || isInitialLoad) {
      return;
    }
    const ganttInstance = ganttRef.current.instance;
    ganttInstance.zoomOut();
    setZoomLevel(ganttInstance.zoomLevel);
    ganttInstance.fillTicks = ganttInstance.zoomLevel > 8;
    localStorage.setItem(SESSION_STORAGE_GANTT_ZOOM_LEVEL, ganttInstance.zoomLevel.toString());
  }, []);

  const handleZoomToFit = useCallback(() => {
    if (!ganttRef.current || isInitialLoad) {
      return;
    }
    const ganttInstance = ganttRef.current.instance;
    ganttInstance.zoomToFit();
  }, []);

  const handleShiftNext = useCallback(() => {
    if (!ganttRef.current || isInitialLoad) {
      return;
    }

    const ganttInstance = ganttRef.current.instance;
    const unit = (ganttInstance.viewPreset as ViewPreset).mainUnit;
    const unitShortHand = getShortNameOfUnit(unit);
    ganttInstance.shift(1, unitShortHand);
  }, []);

  const handleShiftPrevious = useCallback(() => {
    if (!ganttRef.current || isInitialLoad) {
      return;
    }
    const ganttInstance = ganttRef.current.instance;
    const unit = (ganttInstance.viewPreset as ViewPreset).mainUnit;
    const unitShortHand = getShortNameOfUnit(unit);
    ganttInstance.shift(-1, unitShortHand);
  }, []);

  const handleApplyFilters = async (filters: any) => {
    if (!ganttRef.current || isInitialLoad) {
      return;
    }
    const ganttInstance = ganttRef.current.instance;

    ganttInstance.features.filter = filters;
    ganttInstance.project.load();
  };

  const handleAddTask = useCallback(async () => {
    if (!ganttRef.current || isInitialLoad) {
      return;
    }
    const ganttInstance = ganttRef.current.instance;
    const added = ganttInstance.taskStore.add(DEFAULT_TIMELINE_ITEM);

    // run propagation to calculate new task fields
    await ganttInstance.project.commitAsync();

    // scroll to the added task
    await ganttInstance.scrollRowIntoView(added[0]);

    ganttInstance.startEditing({
      record: added[0],
      field: 'name',
    });
  }, []);

  const handleImportData = async (msProjectData: any) => {
    if (!ganttRef.current || isInitialLoad) {
      return;
    }
    const ganttInstance = ganttRef.current.instance;

    ganttInstance.maskBody('Importing project ...');

    const importHelper = new MsProjectImportHelper(ganttInstance);

    // Import the uploaded mpp-file data

    await importHelper.loadMsProject(msProjectData);

    // set the view start date to the loaded project start
    ganttInstance.setStartDate(ganttInstance.project.startDate as Date);
    await ganttInstance.scrollToDate(ganttInstance.project.startDate as Date, { block: 'start' });

    // Zoom to fit import project
    if (ganttZoomLevel) {
      ganttInstance.zoomToLevel(ganttZoomLevel);
    }

    // remove "Importing project ..." mask
    ganttInstance.unmaskBody();
  };

  const handleApplyTemplate = useCallback(async (templateItems: Array<any>) => {
    if (!ganttRef.current || isInitialLoad) {
      return;
    }
    const ganttInstance = ganttRef.current.instance;

    const applyTemplateHelper = new PulseTimelineApplyTemplateHelper(ganttInstance.project.dependencyStore);

    ganttInstance.mask('Applying Template');

    const shiftedTemplates = applyTemplateHelper.shiftTemplateTaks(templateItems, ganttInstance.project.startDate);

    // Add tasks
    const added = ganttInstance.taskStore.add(applyTemplateHelper.generatedTask(shiftedTemplates));

    // Add dependency
    ganttInstance.project.dependencyStore.add(applyTemplateHelper.generatedDependency());

    // run propagation to calculate new task fields
    await ganttInstance.project.commitAsync();

    // scroll to the added task
    added && (await ganttInstance.scrollRowIntoView(added[added.length - 1]));

    ganttInstance.unmask();
  }, []);

  const debouncedSearch = debounce(value => {
    if (!ganttRef.current || isInitialLoad) {
      return;
    }
    const ganttInstance = ganttRef.current.instance;
    if (!value) {
      ganttInstance.taskStore.clearFilters();
      return;
    }
    const searchValue = value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const searchValueRegex = new RegExp(searchValue, 'i');

    ganttInstance.taskStore.filter({
      filters: task => {
        // Filtering should happen with assignees too.
        const filteredAssignements = ganttInstance.assignments.filter(
          (item: any) => item.resource.name.match(searchValueRegex) && item.event.id === task.id,
        );

        return (
          task.name?.match(searchValueRegex) ||
          task.id.toString().match(searchValueRegex) ||
          filteredAssignements.length
        );
      },
      replace: true,
    });
  }, 300);

  const proceedExport = async (fileExportType: string) => {
    if (!ganttRef.current || isInitialLoad) {
      return;
    }
    const ganttInstance = ganttRef.current.instance;
    ganttInstance.setConfig({ zoomLevel: 10 });
    switch (fileExportType) {
      case GANTT_EXPORT_FILE_TYPES.FILE_PDF:
        window.utilities?.blockPage();
        await getJWTToken().then(res => {
          const authToken = res.data.access_token;
          ganttInstance.features.pdfExport.setConfig({
            fetchOptions: {
              headers: {
                accept: 'application/vnd.api+json',
                'content-type': 'application/json',
                Authorization: authToken,
              },
            },
            exportMask: 'Generating timeline export...',
            exportProgressMask: 'Generating timeline export...',
            fileName: getFileNameWithTimestamp(rollup ? rollup?.title : timeline?.timelineName),
          });
        });
        ganttInstance.features.pdfExport.showExportDialog();
        window.utilities?.unblockPage();
        break;
      case GANTT_EXPORT_FILE_TYPES.FILE_MSP:
        ganttInstance.features.mspExport.export({
          filename: getFileNameWithTimestamp(rollup ? rollup?.title : timeline?.timelineName),
        });
        break;
      case GANTT_EXPORT_FILE_TYPES.FILE_EXCEL:
        ganttInstance.features.excelExporter.export({
          filename: getFileNameWithTimestamp(rollup ? rollup?.title : timeline?.timelineName),
        });
        break;
      default:
        break;
    }
  };

  const handleExport = useCallback(
    (fileExportType: string) => {
      if (changeCount || timeRangeChangeCount) {
        return setConfirmLeave({
          isOpen: true,
          proceedCallback: () => proceedExport(fileExportType),
        });
      }

      proceedExport(fileExportType);
    },
    [changeCount, timeRangeChangeCount],
  );

  const handleManageNonWorkingDaysModal = useCallback(() => {
    if (changeCount || timeRangeChangeCount) {
      return setConfirmLeave({
        isOpen: true,
        proceedCallback: () => setShowNonWorkingDaysModal(true),
      });
    }
    setShowNonWorkingDaysModal(true);
  }, [changeCount, timeRangeChangeCount]);

  const hanldeImportFileModal = useCallback(() => {
    if (changeCount || timeRangeChangeCount) {
      return setConfirmLeave({
        isOpen: true,
        proceedCallback: () => setShowFileImporterModal(true),
      });
    }
    setShowFileImporterModal(true);
  }, [changeCount, timeRangeChangeCount]);

  const handleDeleteTask = useCallback(() => {
    if (!ganttRef.current) {
      return;
    }
    const gantt = ganttRef.current.instance;

    gantt.taskStore.remove(deleteRecords);
    setShowDeleteModal(false);
  }, [deleteRecords, showDeleteModal]);

  const handleCloseTaskDeleteModal = useCallback(() => setShowDeleteModal(false), []);

  const handleOpenPhaseFlyout = (marker?: TimelineMarker) => {
    if (!ganttRef.current || isInitialLoad) {
      return;
    }
    const ganttInstance = ganttRef.current.instance;
    const timeRangeStore = ganttInstance.project.timeRangeStore;
    let flyout = timelinePhasesFlyout;

    // Close flyout if click to "Phase" button 2 times
    if (flyout && !marker?.name) {
      flyout.destroy();
      return;
    }

    // Create new flyout if it not exist
    if (!flyout) {
      const splitter = new Splitter({
        appendTo: 'pulse-timeline-gantt',
      });

      flyout = new TimelinePhasesFlyout({
        ...gridTimelinesPhaseProps,
        height: ganttInstance.height,
        appendTo: 'pulse-timeline-gantt',
        flex: '0 0 320px',
        store: timeRangeStore,
        tbar: [
          {
            type: 'button',
            text: 'Add phase',
            icon: 'b-fa-plus',
            onClick() {
              const added = timeRangeStore.add({
                name: 'New range',
                startDate: ganttInstance.project.startDate,
                duration: 0,
              });
              flyout && flyout.scrollRowIntoView(added[0]);

              flyout &&
                flyout.startEditing({
                  record: added[0],
                  field: 'name',
                });
            },
          },
          {
            type: 'button',
            icon: 'b-fa-times',
            color: 'b-white',
            onClick() {
              splitter.destroy();
              flyout && flyout.destroy();
            },
          },
        ],
        onPaste: (source: any) => {
          // remove readonly if user copy the projected dates
          source.records.forEach((it: any) => {
            it.readOnly = false;
          });
        },
      });

      // storage splitter for destroy when flyout closed
      flyout.splitter = splitter;

      flyout.onBeforeDestroy = () => {
        // Destroy splitter
        flyout?.splitter?.destroy();
        // Clear state
        timelinePhasesFlyout = undefined;
      };

      timelinePhasesFlyout = flyout;
    }
    // Add new row to phases, with start date is selected task
    if (marker?.name) {
      const added = timeRangeStore.add(marker);

      flyout.scrollRowIntoView(added[0]);

      flyout.startEditing({
        record: added[0],
        field: 'name',
      });
    }
  };

  const handleSetBaseline = useCallback(
    (baselineNo: number, isConfirm?: boolean) => {
      if (!ganttRef.current || isInitialLoad) {
        return;
      }
      const ganttInstance = ganttRef.current.instance;

      ganttInstance.project.stm.autoRecord = false;

      if (baselines.includes(baselineNo) && !isConfirm) {
        setSelectedBaselines(baselineNo);
      } else {
        ganttInstance.taskStore.setBaseline(baselineNo);

        setBaselines([...baselines, baselineNo]);

        setChangeCount(change => change + 1);

        setSelectedBaselines(0);
      }

      ganttInstance.project.stm.autoRecord = true;
    },
    [baselines],
  );

  const handleShowBaseline = useCallback(
    (baselineNo: number) => {
      if (!ganttRef.current || isInitialLoad) {
        return;
      }
      const ganttInstance = ganttRef.current.instance;

      // Show no baseline modal
      if (!baselineNo) {
        return setShowNoBaselineModal(true);
      }
      ganttInstance.features.baselines.disabled = false;

      const visible = currentBaselines.includes(baselineNo);

      ganttInstance.element.classList[visible ? 'remove' : 'add'](`b-show-baseline-${baselineNo}`);
      if (visible) {
        const nextBaselines = [...currentBaselines].filter(baseline => baseline !== baselineNo);
        if (!nextBaselines.length) {
          ganttInstance.features.baselines.disabled = true;
        }
        setCurrentBaselines([...nextBaselines]);
      } else {
        setCurrentBaselines([...currentBaselines, baselineNo]);
      }
    },
    [currentBaselines],
  );

  const handleUndo = () => {
    if (!ganttRef.current) {
      return;
    }
    const ganttInstance = ganttRef.current.instance;

    if (ganttInstance.project?.stm.canUndo) {
      ganttInstance.project.stm.undo();
      setUndoCount(undoCount - 1);
      setChangeCount(changeCount - 1);
      setRedoCount(redoCount + 1);
    }
  };

  const handleRedo = () => {
    if (!ganttRef.current) {
      return;
    }
    const ganttInstance = ganttRef.current.instance;

    if (ganttInstance.project.stm.canRedo) {
      ganttInstance.project.stm.redo();
      setUndoCount(undoCount + 1);
      setRedoCount(redoCount - 1);
      setChangeCount(changeCount + 1);
    }
  };

  const handleOpenSaveAsTemplateModal = useCallback(() => {
    if (changeCount || timeRangeChangeCount) {
      return setConfirmLeave({
        isOpen: true,
        proceedCallback: onOpenSaveAsTemplate,
      });
    }
    if (!ganttRef.current || !onOpenSaveAsTemplate) {
      return;
    }

    return onOpenSaveAsTemplate();
  }, [changeCount || timeRangeChangeCount]);

  const handleSyncItems = async () => {
    if (!ganttRef.current) {
      return;
    }

    const ganttInstance = ganttRef.current.instance;
    window.utilities?.blockPage();

    const { stm } = ganttInstance.project;

    stm.disable();

    await ganttInstance.project.sync();

    stm.resetQueue();

    stm.enable();

    window.utilities?.unblockPage();
  };

  const handleCancelUnsaved = () => {
    if (!ganttRef.current) {
      return;
    }
    const ganttInstance = ganttRef.current.instance;

    ganttInstance.project.clearChanges();

    const { stm } = ganttInstance.project;

    // Revert changes
    stm.undoAll();

    // Reset undo and redo after sync
    stm.disable();
    stm.resetQueue();
    stm.enable();

    if (confirmLeave.proceedCallback) {
      confirmLeave.proceedCallback();
    }

    setConfirmLeave({
      isOpen: false,
    });
  };

  const handleSaveNonWorkingItems = async (nonWorkingDays: Array<NonWorkingDaysModel>, shouldReload: boolean) => {
    if (!ganttRef.current) {
      return;
    }

    hasNonworkingTimeChangesRef.current = true;
    const ganttInstance = ganttRef.current.instance;

    if (nonWorkingDays.length) {
      // Sync gantt if enable/disabled office non-working day.
      const calendarStore = ganttInstance.project.calendar.calendarManagerStore;

      calendarStore.beginBatch();
      const calendar = calendarStore.getById('business');
      const intervals = calendar.get('intervals');

      intervals.forEach(interval => {
        let nonWorkingDay = nonWorkingDays.find(it => it.id === interval.id);

        if (nonWorkingDay) {
          interval.isWorking = nonWorkingDay.isWorking;
        }

        if (interval.cls === 'weekend') {
          nonWorkingDay = nonWorkingDays.find(it => it.cls === 'weekend');
          if (nonWorkingDay) {
            interval.isWorking = nonWorkingDay.isWorking;
          }
        }
      });
      calendar.set('intervals', nonWorkingDays);

      calendarStore.endBatch();

      await handleSyncItems();

      // Need to add this line as there were still a calendar object sent while we sync the timeline after a nonworking time changes.
      ganttInstance.project.calendar.clearChanges();
    } else if (shouldReload) {
      // Reload gantt if add/remove timeline non-working day.
      window.utilities?.blockPage();
      // return window.location.reload();
    }

    setShowNonWorkingDaysModal(false);
  };

  const handleShowProjectDates = useCallback(
    (enabled?: boolean) => {
      // Do nothing if gantt ref is null or undefined
      if (!ganttRef?.current) {
        return;
      }
      const ganttInstance = ganttRef.current.instance;
      const timeRangeStore = ganttInstance.features.timeRanges.store;

      if (enabled) {
        const projectDates: Array<any> = [];
        if (project?.startdate) {
          projectDates.push({
            id: 'prj-start',
            name: 'Projected Start',
            startDate: moment(project.startdate || project.projectedstartdate)
              .startOf('day')
              .toDate(),
            duration: 0,
            readOnly: true,
          });
        }
        if (project?.enddate) {
          projectDates.push({
            id: 'prj-end',
            name: 'Projected End',
            startDate: moment(project.enddate || project.projectedenddate)
              .endOf('day')
              .toDate(),
            duration: 0,
            readOnly: true,
          });
        }
        // add to time ranges if project dates exists.
        Boolean(projectDates.length) && timeRangeStore.add(projectDates);
      } else {
        // Find added projects dates records
        const prjStartDate = timeRangeStore.findRecord('id', 'prj-start');
        const prjEndDate = timeRangeStore.findRecord('id', 'prj-end');

        // Remove project dates records
        prjStartDate && timeRangeStore.remove(prjStartDate);
        prjEndDate && timeRangeStore.remove(prjEndDate);
      }
    },
    [ganttRef.current],
  );

  const handleDisplayChanged = useCallback(
    (display: GanttDisplayModel) => {
      // Do nothing if gantt ref is null or undefined
      if (!ganttRef?.current) {
        return;
      }
      const ganttInstance = ganttRef.current.instance;
      switch (display.name) {
        case 'project-labels':
          updateSessionStorageGanttDisplayConfig(display);
          ganttInstance.features.labels.disabled = !display.enabled;
          return;
        case 'project-dates':
          updateSessionStorageGanttDisplayConfig(display);
          // Toggle project lines features
          handleShowProjectDates(display.enabled);
          return;
        case 'project-baselines':
          ganttInstance.features.baselines.setConfig({ disabled: !display.enabled });
          return;
        case 'project-phases':
          updateSessionStorageGanttDisplayConfig(display);
          handleShowProjectPhase(display.enabled ?? false);
          return;
      }
    },
    [ganttRef?.current],
  );

  const handleShowProjectPhase = (show: boolean) => {
    const ganttRoot = document.getElementById('pulse-timeline-gantt');
    if (show) {
      ganttRoot?.classList.remove('pulse-gantt--timeranges');
    } else {
      ganttRoot?.classList.add('pulse-gantt--timeranges');
    }
  };

  const ganttFeatureDisplay = localStorage.getItem(SESSION_STORAGE_GANTT_DISPLAY_CONFIG);

  const ganttConfig = useMemo<Partial<BryntumGanttProps>>(() => {
    const columnsConfigsSessionStorage = localStorage.getItem(SESSION_STORAGE_COLUMNS_CONFIGS_KEY);
    const isShowSchedule = !!window.localStorage.getItem(SESSION_STORAGE_SUB_GRID_CONFIGS_VISIBLE);
    const lockedSubGridWidth =
      isShowSchedule === true
        ? localStorage.getItem(SESSION_STORAGE_SUB_GRID_WIDTH_CONFIGS_KEY) || ''
        : DEFAULT_SUB_GRID_WIDTH;
    let columns = DEFAULT_COLUMNS;
    registerIdColumn();
    if (columnsConfigsSessionStorage) {
      columns = orderBy(
        DEFAULT_COLUMNS.map((column: Partial<GridColumnConfig>) => {
          const index = (column as any).type || '';
          const columnConfigs = JSON.parse(columnsConfigsSessionStorage)[index];
          return {
            ...column,
            ...columnConfigs,
          };
        }),
        column => column.order,
      );
    }

    const config: Partial<BryntumGanttProps> = {
      project: {
        autoLoad: true,
        autoSync: false,
        calendar: 'business',
        calendarModelClass: TimelineGanttCalendarModel,
        transport: !showMock
          ? {
              load: {
                url: loadUrl,
                params: {
                  jq: '.data.attributes',
                },
              },
              sync: {
                url: syncUrl,
                headers: {
                  'X-Requested-With': 'XMLHttpRequest',
                },
              },
            }
          : undefined,
        stm: {
          autoRecord: true,
          listeners: {
            queueReset: () => {
              setRedoCount(0);
              setUndoCount(0);
              setChangeCount(0);
            },
            recordingStop: ({ stm }) => {
              setUndoCount(stm.length);
              if (stm.length) {
                setChangeCount(changeCount => changeCount + 1);
              }
              setRedoCount(0);
            },
            restoringStop: (source: any) => {
              setUndoCount(source.stm.position);
              setChangeCount(source.stm.position);
              setRedoCount(source.stm.length - source.stm.position);
            },
          },
        },
        resetUndoRedoQueuesAfterLoad: true,
        taskModelClass: PulseTimelineTaskModel,
      },
      enableUndoRedoKeys: true,
      // Config that stretches the tasks to fill whole ticks
      fillTicks: ganttZoomLevel > 8,
      // Make tasks snap when resizing / dragging. When combined with fillTicks it automatically snaps to full ticks
      snap: true,
      barMargin: 8,
      cellEditFeature: {
        addNewAtEnd: DEFAULT_TIMELINE_ITEM,
      },
      rowHeight: 40,
      columns,
      transitionDuration: 0,
      dependencyEditFeature: true,
      filterFeature: true,
      sortFeature: true,
      loadMask: null,
      infiniteScroll: true,
      // Give lots of smooth scrolling range to touch based scroll devices
      bufferCoef: globalThis.matchMedia('(any-pointer:coarse)').matches ? 10 : 5,
      listeners: {
        taskMenuBeforeShow,
        export: handleExport,
        beforeTaskDrag({ taskRecord }) {
          if (taskRecord.children?.length) {
            return false;
          }
        },
        presetChange({ zoomLevel }) {
          if (!ganttRef.current || isInitialLoad) {
            return;
          }
          const ganttInstance = ganttRef.current.instance;
          setZoomLevel(zoomLevel);
          ganttInstance.fillTicks = ganttInstance.zoomLevel > 8;
          localStorage.setItem(SESSION_STORAGE_GANTT_ZOOM_LEVEL, ganttInstance.zoomLevel.toString());
        },
        beforeFinishCellEdit(this: any, { editorContext }) {
          const { column, editor } = editorContext;
          // Does user edited startdate column
          if (column.data?.id === 'startdate') {
            // Get selected start date value
            const startDate = editor.inputField.value;

            // Compare with current project start date
            if (isBefore(startDate, this.project.startDate) && !isSameDay(startDate, this.project.startDate)) {
              // Set new value to allow create item before current project start date
              this.project.set({
                startDate: startDate,
              });
            }
          }
        },
        taskDrag(this: any, e) {
          if (isBefore(e.startDate, this.project.startDate) && !isSameDay(e.startDate, this.project.startDate)) {
            /**
             * Update project start date on drag to make sure that
             *  users can drag to before project start date
             */
            this.project.set({
              startDate: e.startDate,
            });
          }
        },
        beforeDependencyCreateFinalize(this: any, e) {
          // getting task object from gantt
          const task = this.taskStore.getById(e.target.data.id);

          // setting constraint to null so that it wll jump back to the finish of predecessor task
          task.set({
            constraintType: null,
            constraintDate: null,
          });
        },
        beforeDragCreateFinalize(this: any, { context, eventRecord }) {
          const { startDate, endDate } = context;

          const contextStartDate = moment(startDate);
          const contextEndDate = moment(endDate);
          // Turn off auto stm
          this.project.stm.disable();

          // Get non-working dates
          const calendarStore = this.project.calendar.calendarManagerStore;
          const calendar = calendarStore.getById('business');
          const intervals = calendar.get('intervals');
          let nonWorkingStart: any;
          let nonWorkingEnd: any;

          let allowCreated = true;
          for (const interval of intervals) {
            // Get non-working ranges
            if (interval.startDate && interval.endDate) {
              nonWorkingStart = moment(interval.startDate);
              nonWorkingEnd = moment(interval.endDate);

              // Create item in non-working ranges.
              // nonworkingStart <= startDate < nonWorkingEnd
              // or nonworkingStart < endDate <= nonWorkingEnd
              if (
                contextStartDate.isBetween(nonWorkingStart, nonWorkingEnd, 'days', '[)') ||
                contextEndDate.isBetween(nonWorkingStart, nonWorkingEnd, 'days', '(]')
              ) {
                allowCreated = false;
                break;
              }
            }
          }

          // Enable async to control item create
          context.async = true;

          if (allowCreated) {
            // Item was created in working times, start to check project start date
            if (
              isBefore(contextStartDate.toDate(), this.project.startDate) &&
              !isSameDay(contextStartDate.toDate(), this.project.startDate)
            ) {
              /**
               * Update project start date on drag to make sure that
               *  users can drag to before project start date
               */
              this.project.set({
                startDate: contextStartDate,
              });
            }

            this.project.stm.enable();
            context.finalize(true);
          } else {
            // Item was created in non-working times
            // Show flash error message
            window?.utilities?.notification.danger('Items cannot be scheduled on non-working days.');
            // Ignore changes
            context.finalize(false);

            // Reset values
            eventRecord.set({
              endDate: '',
            });

            // Enable stm again
            this.project.stm.enable();
          }
        },
      },

      // percentBarFeature: {
      //   valueField: 'progress',
      // },
      projectLinesFeature: {
        disabled: true,
      },
      regionResizeFeature: true,
      subGridConfigs: {
        locked: {
          width: parseInt(lockedSubGridWidth, 10),
        },
      },
      syncMask: null,
      selectionMode: {
        row: true,
        cell: false,
        multiSelect: true,

        // This flag controls whether the Grid should deselect a selected row when clicking it
        deselectOnClick: false,

        // This flag controls whether the Grid should preserve its selection of cells / rows when loading a new dataset (assuming the selected records are included in the newly loaded dataset)
        preserveSelectionOnDatasetChange: true,
      },
      taskMenuFeature: {
        items: {
          unlinkTasks: false, // Disable default remove dependencies between multiple task

          // Customize remove dependency
          removeDependency: {
            text: 'Remove Dependency',
            icon: 'b-fa b-fa-unlink',
            menu: [],
          },
          deleteTask: {
            text: 'Delete',
            icon: 'b-fa b-fa-trash',
            onItem() {
              const ganttInstance = ganttRef?.current?.instance;
              if (!ganttInstance) {
                return;
              }
              setDeleteRecords(ganttInstance.selectedRecords);
              setShowDeleteModal(true);
            },
          },
          addMarker: {
            text: 'Add Marker',
            icon: 'b-fa b-fa-highlighter',
            onItem({ domEvent }) {
              const ganttInstance = ganttRef?.current?.instance;
              if (!ganttInstance) {
                return;
              }
              const date = ganttInstance.getDateFromDomEvent(domEvent);
              handleOpenPhaseFlyout({
                name: 'New range',
                startDate: date,
                duration: 0,
              });
            },
          },
        },
      },
      taskRenderer: ({ taskRecord, renderData }) => {
        const eventColor = taskRecord.eventColor || taskRecord.getData('color');
        // Customize color for task
        if (eventColor && !taskRecord.isMilestone && !taskRecord.isParent) {
          const timelineColor = DEFAULT_TIMELINE_COLORS.find(it => it.name === eventColor);
          const colorCode = timelineColor?.colorCode || DEFAULT_TIMELINE_COLORS[0].colorCode;
          renderData.style += `background-color:${colorCode}`;
        }
      },
      taskTooltipFeature: false,
      timeRangesFeature: {
        showCurrentTimeLine: {
          name: 'Today',
        },
        showHeaderElements: true,
        disabled: false,
      },
      mspExportFeature: {
        filename: timeline?.timelineName,
        dateFormat: currentUserTimeFormat,
      },
      pdfExportFeature: {
        openAfterExport: true,
        headerTpl: headerTpl,
        exportServer: getPDFExportURL(),
        exportDialog: {
          bodyCls: 'pulse-timeline__export-dialog',
          header: {
            cls: `${styles['dialog-pdf']} fal fa-file-pdf`,
          },
          title: 'Export as PDF/PNG',
          autoSelectVisibleColumns: false,
          bbar: {
            items: {
              exportButton: {
                icon: 'fal fa-download',
              },
            },
          },
          listeners: {
            beforeShow(this: any) {
              this.widgetMap.scheduleRangeField.value = 'currentview';
              this.widgetMap.scheduleRangeField.items.shift();

              const columnField = this.widgetMap.columnsField;
              if (columnField) {
                const preselectedColumns = ['sequence', 'name', 'startdate', 'enddate'];
                columnField.value = preselectedColumns;

                const columnTitle = columnField.element.getElementsByClassName('b-label');
                columnTitle[0].style.alignSelf = 'start';
                columnTitle[0].style.marginTop = '8px';
                console.log('columnTitle', columnTitle);
                const infoDiv = document.createElement('div');
                const infoIcon = document.createElement('span');
                infoIcon.className = 'fa-solid fa-circle-info';
                infoIcon.style.fontSize = '12px';
                infoIcon.style.marginTop = '1px';

                infoDiv.appendChild(infoIcon);
                const infoText = document.createElement('span');
                infoDiv.style.color = 'black';
                infoText.style.fontSize = '12px';
                infoDiv.style.position = 'absolute';
                infoDiv.style.display = 'flex';
                infoDiv.style.flexDirection = 'column';
                infoDiv.style.alignItems = 'end';
                infoDiv.style.top = '25px';
                infoDiv.style.left = '6px';
                infoDiv.style.width = '140px';
                infoText.style.textAlign = 'right';
                infoText.innerText = 'For the best results, limit to a maximum of 4 columns.';
                infoDiv.appendChild(infoText);
                if (columnField.element) {
                  columnField.element.style.minHeight = '86px';
                  columnField.element.appendChild(infoDiv);
                }
              }
            },
          },
          items: {
            scheduleRangeField: {
              value: 'currentview',
            },
            columnsField: {
              value: ['id', 'name', 'startdate', 'duration', 'endate'],
            },
            rowsRangeField: {
              value: 'all',
            },
            exporterTypeField: {
              value: 'multipage',
            },
            alignRowsField: {
              checked: true,
            },
            fileFormatField: {
              value: 'pdf',
            },
            paperFormatField: {
              value: 'A3',
            },
            orientationField: {
              value: 'landscape',
            },
          },
        },
      },
      excelExporterFeature: {
        zipcelx,
        dateFormat: currentUserTimeFormat ? currentUserTimeFormat : 'dd/MM/YYYY',
      },
      baselinesFeature: true,

      nonWorkingTimeFeature: {
        highlightWeekends: true,
      },
      // Allow to show non-working time in each timeline when access in Roll-Up view
      taskNonWorkingTimeFeature: Boolean(rollup)
        ? {
            mode: 'row',
          }
        : {
            disabled: true,
          },
      maxZoomLevel: 10,
      minZoomLevel: 0,
      zoomLevel: ganttZoomLevel,
      labelsFeature: {
        disabled: ganttFeatureDisplay ? !ganttFeatureDisplay.includes('project-labels') : true,
        left: {
          field: 'name',
        },
      },
      taskEditFeature: {
        editorConfig: {
          bbar: {
            items: {
              deleteButton: {
                cls: 'b-transparent',
                icon: 'fal fa-trash-alt',
                text: 'Delete item',
              },
              saveButton: {
                cls: 'b-green',
                icon: 'fal fa-sync-alt',
                text: 'update',
              },
            },
          },
          id: 'pulse-timeline-gantt-task-editor',
          autoClose: false,
          listeners: {
            beforeShow: (event: { source: any; type: string }) => {
              event.source.title = `ITEM: ${event.source.loadedRecord.data.name}`;
            },
          },
        },
        items: {
          generalTab: {
            items: {
              name: {
                label: 'Title',
              },
              effort: false,
              divider: false,
              percentDone: {
                label: '% Progress',
                name: 'percentDone',
                flex: '1 1 calc(50% - 11px)',
                style: 'order: 1',
              },
              timelineColorField: {
                type: 'timelineColorField',
                name: 'eventColor',
                label: 'Color',
                flex: '1 1 calc(50% - 11px)',
                style: 'order: 2',
                allowClear: false,
              },
              startDate: {
                flex: '1 1 calc(50% - 11px)',
                style: 'order: 3',
                type: 'date',
                listeners: {
                  change({ userAction, value }) {
                    const ganttInstance = ganttRef?.current?.instance;
                    if (!ganttInstance) {
                      return;
                    }

                    // Only check project start date if date was set by user
                    if (userAction) {
                      // Compare with current project start date
                      if (
                        isBefore(value, ganttInstance.project.startDate as Date) &&
                        !isSameDay(value, ganttInstance.project.startDate as Date)
                      ) {
                        // Set new value to allow create item before current project start date
                        ganttInstance.project.set({
                          startDate: value,
                        });
                      }
                    }
                  },
                },
              },
              endDate: {
                flex: '1 1 calc(50% - 11px)',
                style: 'order: 4',
              },
              duration: {
                flex: '0 1 calc(50% - 11px)',
                style: 'order: 5',
              },
            },
          },
          advancedTab: {
            items: {
              calendarField: false,
              manuallyScheduledField: false,
              schedulingModeField: false,
              effortDrivenField: false,
              divider: false,
              rollupField: false,
              inactiveField: false,
              ignoreResourceCalendarField: false,
              projectConstraintResolutionField: false,
              constraintTypeField: {
                flex: '1 1 auto',
              },
              constraintDateField: {
                flex: '1 1 auto',
              },
            },
          },
          predecessorsTab: {
            items: {
              toolbar: {
                items: {
                  remove: {
                    cls: 'b-transparent',
                    icon: 'fal fa-trash-alt',
                  },
                },
              },
            },
          },
          successorsTab: {
            items: {
              toolbar: {
                items: {
                  remove: {
                    cls: 'b-transparent',
                    icon: 'fal fa-trash-alt',
                  },
                },
              },
            },
          },
          resourcesTab: {
            title: 'Assigned',
            items: {
              grid: {
                columns: {
                  // Columns are held in a store, thus it uses `data`
                  // instead of `items`
                  data: {
                    resource: {
                      // Change header text for the resource column
                      text: 'User',
                    },
                  },
                },
              },
              toolbar: {
                items: {
                  remove: {
                    cls: 'b-transparent',
                    icon: 'fal fa-trash-alt',
                  },
                },
              },
            },
          },
          notesTab: {
            tab: {
              icon: null,
              text: 'Notes',
            },
            items: {
              noteField: {
                placeholder: 'Add a note here...',
              },
            },
          },
          linkedTab: {
            title: 'Linked Task',
            // weight values for order tabs
            // 450 => display between assigned (400) and advanced (500) tabs
            weight: 450,
            // content of tab will be set in 'beforeTaskEditShow' event
            html: '',
          },
        },
      },
      cycleResolutionPopupClass: CustomCycleResolutionPopup,
      headerMenuFeature: {
        processItems({ items }) {
          const { columnPicker } = items;
          const menu = (columnPicker as any).menu;
          // exclude sequence column in the column picker
          const pickerItems = [...menu?.items];
          if (menu?.items) {
            (items.columnPicker as any).menu.items = pickerItems?.filter(picker => picker.column.id !== 'sequence');
          }
        },
      },
    };

    return config;
  }, [loadUrl, syncUrl, projectTitle, projectId]);

  window.onbeforeunload = event => {
    if (changeCount + timeRangeChangeCount) {
      event.preventDefault();
      return 'You have unsaved changes, are you sure you want to proceed ?';
    }
  };

  useEffect(() => {
    if (!ganttRef.current) {
      return;
    }
    const ganttInstance = ganttRef.current.instance;

    if (showMock) {
      ganttInstance.project.loadInlineData({
        resourcesData: ganttMockup.resources.rows,
        dependenciesData: ganttMockup.dependencies.rows,
        assignmentsData: ganttMockup.assignments.rows,
        timeRangesData: ganttMockup.timeRanges.rows,
        tasksData: ganttMockup.tasks.rows,
        calendarsData: ganttMockup.calendars.rows,
      });
    }

    // Enable undo and redo feature
    ganttInstance.project.stm.enable();

    // set presets for gantt
    ganttInstance.presets = ganttInstance.presets?.map(preset => {
      if (preset.id === 'weekAndDayLetter') {
        return {
          ...presets[0],
          base: preset.id,
        };
      }
      return {
        id: `pulse_${preset.id}`,
        base: preset.id,
        timeResolution: {
          unit: 'day',
          increment: 1,
        },
      };
    });

    ganttInstance.viewPreset = 'pulseTimelineWeekAndDate';

    ganttInstance.taskStore.on('change', function ({ record, changes }) {
      if (changes) {
        const { percentDone } = changes;
        if (percentDone) {
          const { value } = percentDone;

          record.set({
            status: getStatusString(value),
            progress: value,
          });
        }
      }
    });

    ganttInstance.taskStore.on('indent', function ({ records }) {
      const parentRecordIds = uniq<string | number>(records.map(record => record.parentId));
      parentRecordIds.forEach(parentRecordId => {
        const parentRecord = ganttInstance.taskStore.getById(parentRecordId) as any;

        let percentDone = 0;
        parentRecord.children.forEach(child => {
          percentDone = percentDone + child.data.progress;
        });

        const percent = parentRecord.children.length ? percentDone / parentRecord.children.length : 0;

        parentRecord.set({
          constraintType: null,
          constraintDate: null,
          progress: percent,
          percentDone: percent,
          status: getStatusString(percent),
        });
      });
      ganttInstance.deselectAll();
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    ganttInstance.dependencyStore.on('beforeRemove', ({ records }) => {
      /**
       * if predecessor length 0 and constraint type is null,
       * then set constraint type to 'startnoearlierthan'
       */
      records.forEach(record => {
        const fromTask = ganttInstance.taskStore.getById(record.fromTask) as any;
        const toTask = ganttInstance.taskStore.getById(record.toTask) as any;

        /** Check if there is a fromTask because sometimes it's undefined */
        if (fromTask && fromTask.predecessors.length === 1 && !fromTask.constraintType) {
          fromTask.set('constraintType', DEFAULT_CONSTRAINT_TYPE);
        }

        /** Check if there is a toTask because sometimes it's undefined */
        if (toTask && toTask.predecessors.length === 1 && !toTask.constraintType) {
          toTask.set('constraintType', DEFAULT_CONSTRAINT_TYPE);
        }
      });
    });

    /**
     * Listens to changes in the column store
     * of the gantt so we can store the columns
     * preferences of the user
     */
    (ganttInstance.columns as ColumnStore).on('change', () => {
      const columns = (ganttInstance.columns as ColumnStore).records.reduce(
        (results, column: any) => ({
          ...results,
          [column.type]: {
            width: column.width,
            hidden: column.hidden,
            order: column.parentIndex,
          },
        }),
        {},
      );
      localStorage.setItem(SESSION_STORAGE_COLUMNS_CONFIGS_KEY, JSON.stringify(columns));
    });

    ganttInstance.store.onSort = function ({ records }) {
      records.forEach(it => {
        const record = ganttInstance.taskStore.getById(it.id);
        record.set({
          orderIndex: it.parentIndex,
        });
      });
    };

    ganttInstance.project.on({
      beforeSend: ({ params, requestConfig, requestType }) => {
        if (requestType === 'sync') {
          // We don't want to send any automatic changes on tasks dates when we update the calendar.
          const bodyObj = JSON.parse(requestConfig?.body);

          if (hasNonworkingTimeChangesRef.current) {
            delete bodyObj?.tasks;
          }

          hasNonworkingTimeChangesRef.current = false;

          if (bodyObj?.tasks?.updated?.length) {
            bodyObj.tasks.updated = bodyObj.tasks.updated.map(({ orderIndex, ...updater }) => {
              if (isNumber(orderIndex)) {
                updater.parentIndex = orderIndex;
              }
              return updater;
            });
          }
          if (bodyObj?.tasks?.added?.length) {
            bodyObj.tasks.added = bodyObj.tasks.added.map(({ orderIndex, ...added }) => {
              if (isNumber(orderIndex)) {
                added.parentIndex = orderIndex;
              }
              return added;
            });
          }
          requestConfig.body = JSON.stringify(bodyObj);
        }

        // Going to open/modify this when BE added filter to timeline item endpoint
        const appliedFilter = filterToParams(ganttInstance.features.filter);
        const defaultFilter = {
          status: ['any'],
        };

        // handle reset filter
        Object.keys(params).forEach(key => {
          if (key.includes('filter')) {
            delete params[key];
          }
        });

        if (!isEqual(appliedFilter, defaultFilter)) {
          Object.keys(appliedFilter).forEach(key => {
            if (!appliedFilter[key].includes(undefined)) {
              let filterKey = '';
              switch (key) {
                case Filters.assignedUsers:
                  filterKey = 'assigned_user';
                  break;
                case Filters.assignedUserOffice:
                  filterKey = 'assigned_group';
                  break;
                case Filters.createdBy:
                  filterKey = 'created_by';
                  break;
                case Filters.status:
                  filterKey = 'status';
                  break;
                case 'department':
                  filterKey = 'assigned_department';
                  break;
                default:
                  break;
              }

              if (typeof appliedFilter[key] === 'object') {
                if (filterKey === 'status') {
                  appliedFilter[key] = appliedFilter[key].filter(item => item != 'any');
                }

                filterKey &&
                  appliedFilter[key].forEach((item, index) => {
                    params[`filter[${filterKey}][${index}]`] = item;
                  });
              } else {
                params[`filter[${filterKey}][]`] = appliedFilter[key];
              }
            }
          });
        }
        ganttInstance.disable();
        window.utilities?.dismissNotifications();
      },
      load: () => {
        if (isInitialLoad) {
          isInitialLoad = false;
        }

        if (ganttZoomLevel) {
          ganttInstance.zoomToLevel(ganttZoomLevel);
        }

        // Scroll to first item dates/timeline start date
        ganttInstance.scrollToDate(new Date(ganttInstance.project.startDate), { block: 'start', edgeOffset: 30 });
      },
      requestDone: ({ response }) => {
        const tasks = response?.tasks?.rows || [];
        setShouldEnableSaveAsTemplate(tasks.length !== 0);
        if (tasks.length) {
          const baselines = Math.min(...tasks.map(task => (task.baselines?.length ? task.baselines?.length : 0)));
          if (baselines > 0) {
            const baselineNums = [...Array.from({ length: baselines }, (_, i) => i + 1)];
            setBaselines(baselineNums);
          }
        }

        const calendars = response?.calendars?.rows || [];
        if (calendars.length) {
          const calendar = calendars[0]?.intervals?.find(it => it.cls === 'weekend');
          weekendWorkingRef.current = calendar ? false : true;
        }
        ganttInstance.enable();
        setTimeRangeChangeCount(0);
        setRedoCount(0);
        setChangeCount(0);
        setTimeRangeChangeCount(0);

        // Show project dates if it was set on local storage
        // Only show if gantt wasn't open via public link
        if (!isOpenViaPublicLinks()) {
          handleShowProjectDates(ganttFeatureDisplay ? ganttFeatureDisplay.includes('project-dates') : true);
        }

        window.utilities?.unblockPage();
        window.utilities?.dismissNotifications();
      },
      requestFail: ({ requestType }) => {
        if (isInitialLoad) {
          return;
        }
        ganttInstance.enable();
        window.utilities?.unblockPage();
        window?.utilities?.notification.danger(
          requestType === 'sync' ? 'Failed to save data.' : 'Failed to fetch Timeline data.',
        );
      },
    });

    /**
     * We need to grab the parents and the startDate before
     * the records are outdented so we can then check after
     * the outdent event if the parent records still have
     * children records
     */
    ganttInstance.taskStore.on('beforeOutdent', function (this, { records }) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      this.parentRecordToUpdate = records.map(record => ({
        parentId: record.parentId,
        startDate: self.getById(record.parentId).startDate,
      }));
    });

    /**
     * When a parent does not have anymore children,
     * we convert the parent task into a normal task
     * with the current start and end date of the
     * parent task
     */
    ganttInstance.taskStore.on('outdent', function (this) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      this.parentRecordToUpdate.forEach(parentRecord => {
        const parent = self.getById(parentRecord.parentId);
        let percentDone = 0;

        parent.children?.forEach(child => {
          percentDone = percentDone + child.data.progress;
        });

        const percent = parent.children.length > 0 ? percentDone / parent.children?.length : parent.data.progress;

        if (!parent.children?.length) {
          parent.set({
            status: getStatusString(percent),
            startDate: parentRecord.startDate,
            constraintType: DEFAULT_CONSTRAINT_TYPE,
            constraintDate: parentRecord.startDate,
            progress: percent,
            percentDone: percent,
          });
        } else {
          parent.set({
            status: getStatusString(percent),
            progress: percent,
            percentDone: percent,
          });
        }
      });
    });

    ganttInstance.taskStore.on('beforeUpdate', (source: any) => {
      /**
       * Need update eventto update undo/redo counts
       * so that we can update the badges
       */

      if (isInitialLoad) {
        return;
      }

      return beforeUpdate(source, weekendWorkingRef.current);
    });

    // Disable baseline feature by default
    ganttInstance.features.baselines.disabled = true;

    ganttInstance.project.timeRangeStore.on('change', ({ source }) => {
      const { changes } = source;
      if (changes) {
        const { added = [], modified = [], removed = [] } = changes;
        const excludePrjDates = added.filter((it: any) => !['prj-start', 'prj-end'].includes(it.id));
        setTimeRangeChangeCount(excludePrjDates.length + modified.length + removed.length);
      } else {
        setTimeRangeChangeCount(0);
      }
    });

    ganttInstance.on('paste', ({ records, originalRecords }) => {
      const idMap = {};

      // Id maps for check dependency
      originalRecords.forEach((it, index) => {
        idMap[it.id] = records[index].id;
      });

      // Arrays to store missing dependencies
      const missingDependencies: Array<any> = [];
      // Arrays to store missing assignments
      const missingAssignments: Array<any> = [];

      originalRecords.forEach((element, index) => {
        const copiedAssignments = element.assignments || [];

        const copiedDependencies = element.dependencies;
        const pastedDependencies = records[index].dependencies;

        // When copied items have dependencies to un-copied items
        if (copiedDependencies.length !== pastedDependencies.length) {
          copiedDependencies.forEach(({ originalData }) => {
            const { fromEvent, toEvent, ...rest } = originalData;
            const fromId = typeof fromEvent === 'object' ? fromEvent.id : fromEvent;
            const toId = typeof toEvent === 'object' ? toEvent.id : toEvent;

            // Copied item is children dependency of un-copied items
            if (!idMap[fromId]) {
              missingDependencies.push({
                ...rest,
                fromEvent,
                toEvent: records[index].id,
              });
            } else if (!idMap[toId]) {
              // Copied item is parent dependency of un-copied items
              missingDependencies.push({
                ...rest,
                toEvent,
                fromEvent: records[index].id,
              });
            }
          });
        }

        for (const assignment of copiedAssignments) {
          const { capacity, resource, units } = assignment;
          // Use required values only to create new assignment
          missingAssignments.push({
            units,
            capacity,
            resource,
            event: records[index].id,
            eventId: records[index].id,
          });
        }

        // Clear the linked tasks
        if (records[index].linkedTasks) {
          records[index].set({
            linkedTasks: [],
          });
        }
      });

      // Add missing dependencies
      ganttInstance.project.dependencyStore.add(missingDependencies);

      // Add missing assignments to store
      ganttInstance.project.assignmentStore.add(missingAssignments);

      // Clear copy items
      ganttInstance.features.taskCopyPaste.clearClipboard();
    });

    ganttInstance.on('beforeTaskEditShow', ({ editor, taskRecord }) => {
      const { linkedTasks = [], id } = taskRecord.data;
      const { linkedTab } = editor.widgetMap;
      let currentLinked = linkedTasks.filter((it: any) => {
        const unlinkTask = unlinkTasks.current.find(
          unlinked => it.value || (it.raw?.ticketid === unlinked.ticketId && it.timelineId === id),
        );

        return !unlinkTask;
      });

      if (currentLinked.length) {
        const handleUnlinkTask = async ticketId => {
          window?.utilities?.blockPage();
          // Call unlink tasks api
          await v2Endpoint
            .delete(`/v2/api/timelines/${id}/unlink-task/${ticketId}`)
            .then(() => {
              // Storage unlink task
              unlinkTasks.current.push({
                ticketId,
                timelineItemId: id,
              });

              // Need to update the linkedTasks ids after unlinking the task from timeline item.
              ganttInstance.taskStore.beginBatch();

              taskRecord.data.linkedTasks = taskRecord?.data?.linkedTasks?.filter(item => Number(item.id) !== ticketId);

              ganttInstance.taskStore.endBatch();
              ganttInstance.refreshRows();
              window?.utilities?.notification.success(`Successfully unlinked Task [#${ticketId}] and Item [${id}]`);
            })
            .catch(() => {
              window?.utilities?.notification.danger(`Failed to unlink Task [#${ticketId}] and item [${id}]`);
            })
            .finally(() => {
              window?.utilities?.unblockPage();
            });

          // Get displayed linked tasks
          currentLinked = linkedTasks.filter((it: any) => {
            const unlinkTask = unlinkTasks.current.find(
              unlinked => it.value || (it.raw?.ticketid === unlinked.ticketId && it.timelineId === id),
            );

            return !unlinkTask;
          });

          // Hide tab if current linked task was empty
          if (!currentLinked.length) {
            linkedTab.hidden = true;
          } else {
            // Update tab title
            linkedTab.title = `Linked Tasks (${currentLinked.length})`;
          }

          // Return current linked task list for re-render list
          return currentLinked;
        };

        // Config linked tasks tab
        linkedTab.title = `Linked Tasks (${currentLinked.length})`;
        linkedTab.html = (
          <PulseTimelineGanttLinkedTaskTab linkedTasks={currentLinked} onUnlinkTask={handleUnlinkTask} />
        );
        linkedTab.hidden = false;
      } else {
        // Hide tab when timeline item didn't have linked tasks
        linkedTab.hidden = true;
      }

      const predecessorsTab = editor.widgetMap.predecessorsTab;
      // Check if the sequence column already exists to avoid adding it multiple times
      if (!predecessorsTab.grid.columns.some(column => column.field === 'sequence')) {
        predecessorsTab.grid.columns.insert(0, {
          text: 'ID',
          field: 'sequence',
          width: 100,
          editor: false,
          renderer({ record }) {
            const fromEvent = record.originalData.fromEvent ?? record.fromEvent;

            // When added new records on tabs
            if (!fromEvent) {
              return record.id;
            }
            // Task ID of predecessors will be the fromEvent on original data
            const taskId = typeof fromEvent === 'object' ? fromEvent.id : fromEvent;
            const task = ganttInstance.taskStore.getById(taskId);

            // Sequence Number on Bryntum Gantt was dynamically generated based on the task's position within the grid.
            // So the index of task will be the SequenceNumber, then adding 1 to make it 1-based
            const sequenceNumber = ganttInstance.taskStore.indexOf(task) + 1;

            return sequenceNumber;
          },
        });
      }

      const successorsTab = editor.widgetMap.successorsTab;
      // Check if the sequence column already exists to avoid adding it multiple times
      if (!successorsTab.grid.columns.some(column => column.field === 'sequence')) {
        successorsTab.grid.columns.insert(0, {
          text: 'ID',
          field: 'sequence',
          width: 100,
          editor: false,
          renderer({ record }) {
            const toEvent = record.originalData.toEvent ?? record.toEvent;

            // When added new records on tabs
            if (!toEvent) {
              return record.id;
            }
            // Task ID of predecessors will be the toEvent on original data
            const taskId = typeof toEvent === 'object' ? toEvent.id : toEvent;
            const task = ganttInstance.taskStore.getById(taskId);

            // Sequence Number on Bryntum Gantt was dynamically generated based on the task's position within the grid.
            // So the index of task will be the SequenceNumber, then adding 1 to make it 1-based
            const sequenceNumber = ganttInstance.taskStore.indexOf(task) + 1;

            return sequenceNumber;
          },
        });
      }
    });

    ganttInstance.on('beforePdfExport', () => {
      const { stm } = ganttInstance.project;
      stm.disable();
      window.utilities?.blockPage();
    });

    ganttInstance.on('pdfExport', () => {
      const { stm } = ganttInstance.project;
      stm.enable();
      window.utilities?.unblockPage();
    });
    registerStatusColumn();

    // Show project dates if it was set on local storage
    // Only show if gantt wasn't open via public link
    if (!isOpenViaPublicLinks()) {
      handleShowProjectDates(ganttFeatureDisplay ? ganttFeatureDisplay.includes('project-dates') : true);
    }
    handleShowProjectPhase(ganttFeatureDisplay ? ganttFeatureDisplay.includes('project-phases') : true);
  }, []);

  useEffect(() => {
    if (!ganttRef.current) {
      return;
    }
    const ganttInstance = ganttRef.current.instance;

    // Disabled all gantt feature if user didnt have permission to edit timeline
    ganttInstance.readOnly = !canEditTimeline;

    if (!canEditTimeline) {
      ganttInstance.features.taskMenu.setConfig({
        items: {
          removeDependency: {
            text: 'Remove Dependency',
            icon: 'b-fa b-fa-unlink',
            menu: [],
            disabled: true,
          },
          addMarker: {
            text: 'Add Marker',
            icon: 'b-fa b-fa-highlighter',
            disabled: true,
          },
        },
      });
    }
  }, [canEditTimeline]);

  const handleOnImportedTimelineTask = async (tasks: Array<ImportTaskData>, options: ImportTaskOptions) => {
    if (!ganttRef.current) {
      return;
    }

    const ganttInstance = ganttRef.current.instance;
    // Get list ids for checking linked
    const taskIds = tasks.map(it => it.id);

    // Start a batch changes
    ganttInstance.taskStore.beginBatch();

    const timelineStartDate = moment(timeline?.startDate)?.toDate();

    ganttInstance.taskStore.forEach((record: any) => {
      if (record.taskId && taskIds.includes(record.taskId)) {
        // Remove linked task if it imported and not save
        record.taskId = null;
        record.linkedTasks = [];
      } else if (record.linkedTasks?.length) {
        // Remove linked tasks if it already saved

        // Create temp variables for save changes
        const newLinkedTasks = [...record.linkedTasks];

        let index = 0;
        while (index < newLinkedTasks.length) {
          // Remove linked tasks in current timeline items
          if (taskIds.includes(newLinkedTasks[index].id)) {
            newLinkedTasks.splice(index, 1);
          } else {
            index++;
          }
        }

        record.linkedTasks = newLinkedTasks;
      }
    });

    tasks.forEach(it => {
      const { id, name, startDate, endDate, parent, raw } = it;

      // If defaultStartDate != null => option "Set Start Date" was enable
      const itemStartDate = options.defaultStartDate
        ? moment(options.defaultStartDate)
        : moment(startDate || timeline?.startDate).set('hour', 9);

      const timelineItem: any = {
        name,
        startDate: itemStartDate.format('YYYY-MM-DD hh:mm:ss'),
        taskId: id,
        linkedTasks: [
          {
            id: `${id}`,
            value: id,
            label: name,
            raw: {
              ticketUrl: `${BASE_URL}passport/${project?.jobid}/tasklist/${id}`,
              ticketid: id,
              ticketStatus: {
                name: raw.status.translatedStatusTitle,
                hexColor: raw.status.hexcolor,
              },
            },
          },
        ],
      };
      if (!options.isChainTasks) {
        timelineItem.constraintType = DEFAULT_CONSTRAINT_TYPE;
        timelineItem.constraintDate = itemStartDate;
      }

      // Compare with current project start date
      if (isBefore(itemStartDate.toDate(), timelineStartDate)) {
        // Set new value to allow create item before current project start date
        ganttInstance.project.set({
          startDate: itemStartDate.format('YYYY-MM-DD hh:mm:ss'),
        });
      }

      if (endDate && !options.defaultStartDate) {
        const itemEndDate = moment(endDate).endOf('day');

        // Calculate duration base on start and end dates
        const duration = differenceInBusinessDays(itemEndDate.toDate(), itemStartDate.toDate()) + 1;

        // Set duration for Bryntum auto calculate end date base on calendar config
        timelineItem.duration = duration;
      } else {
        timelineItem.duration = options.duration ?? 3;
      }

      const parentTask = linkedTaskRef.current[parent];
      let addedRecord: Model | Model[] | null = null;
      let fromTask: Model | null = null;
      // Check is children task and parent task has been added
      if (parentTask) {
        if (options.isChainTasks) {
          const lastIndex = parentTask.allChildren.length - 1;
          if (lastIndex != -1) {
            fromTask = parentTask.allChildren[lastIndex];
          }
        }

        addedRecord = parentTask.insertChild(timelineItem);
      } else {
        if (options.isChainTasks) {
          const lastIndex = parentTaskRef.current.length - 1;
          if (lastIndex != -1) {
            fromTask = linkedTaskRef.current[parentTaskRef.current[lastIndex]];
          }
          // Set constraint type if it not parent tasks
          timelineItem.constraintType = DEFAULT_CONSTRAINT_TYPE;
          timelineItem.constraintDate = itemStartDate;
        }
        addedRecord = ganttInstance.taskStore.add(timelineItem);
        parentTaskRef.current.push(id);
      }

      if (!addedRecord) {
        window?.utilities?.notification.danger('Failed when import task to timeline.');
        return;
      }

      // Add to linked task ref for refer later
      if (isArray(addedRecord)) {
        linkedTaskRef.current[id] = addedRecord[0];
      } else {
        linkedTaskRef.current[id] = addedRecord;
      }

      if (fromTask) {
        ganttInstance.project.dependencyStore.add({
          from: fromTask.id,
          to: linkedTaskRef.current[id].id,
        });
      }
    });
    window?.utilities?.notification.success(`Success: ${tasks.length} new Timeline Items created.`);
    ganttInstance.refreshRows();
    ganttInstance.taskStore.endBatch();
    setOpenTaskImportModal(false);
  };

  const handleShowImportTaskModal = () => {
    if (changeCount || timeRangeChangeCount) {
      return setConfirmLeave({
        isOpen: true,
        proceedCallback: () => {
          setOpenTaskImportModal(true);
        },
      });
    }
    setOpenTaskImportModal(true);
  };

  return (
    <>
      {confirmLeave.isOpen && (
        <UnSavedNotificationModal
          isOpen={confirmLeave.isOpen}
          onCancel={() => setConfirmLeave({ isOpen: false })}
          onConfirm={handleCancelUnsaved}
        />
      )}
      <div className={styles['gantt-chart__root']}>
        <PulseTimelineGanttToolbar
          canEditTimelines={canEditTimeline}
          canCreateTemplates={userPermissions?.canCreateTemplates}
          canPromoteToMaster={canPromoteToMaster}
          shouldDisableBaselines={!!rollup}
          baselines={baselines}
          currentBaselines={currentBaselines}
          enableSaveAsTemplate={shouldEnableSaveAsTemplate}
          onApplyFilters={handleApplyFilters}
          onBeforeFilterFlyout={(callback?: ConfirmLeaveCallback) => {
            if (changeCount || timeRangeChangeCount) {
              setConfirmLeave({
                isOpen: true,
                proceedCallback: callback,
              });
              return false;
            }

            return true;
          }}
          onSearch={debouncedSearch}
          templates={templates}
          onAddTask={handleAddTask}
          onSync={handleSyncItems}
          onCollapsed={handleCollapseAll}
          onExpand={handleExpandAll}
          onShiftNext={handleShiftNext}
          onShiftPrevious={handleShiftPrevious}
          onZoomIn={handleZoomIn}
          onZoomOut={handleZoomOut}
          onZoomToFit={handleZoomToFit}
          zoomLevel={zoomLevel}
          onExport={handleExport}
          onRedo={handleRedo}
          undoCount={undoCount}
          redoCount={redoCount}
          changeCount={changeCount + timeRangeChangeCount}
          onUndo={handleUndo}
          onOpenPromoteToMasterModal={onOpenPromoteToMasterModal}
          onOpenPhaseFlyout={handleOpenPhaseFlyout}
          onOpenDeleteTimelineModal={onOpenDeleteTimelineModal}
          onSetBaselines={handleSetBaseline}
          onShowBaselines={handleShowBaseline}
          onManageNonWorkingDaysHanlde={handleManageNonWorkingDaysModal}
          onImportFileModal={hanldeImportFileModal}
          onOpenShareTimelineModal={onOpenShareTimelineModal}
          onApplyTempate={handleApplyTemplate}
          onOpenEditTemplate={onOpenEditTemplate}
          onOpenSaveAsTemplate={handleOpenSaveAsTemplateModal}
          onOpenEditTimelineModal={onOpenEditTimelineModal}
          onDisplayChanged={handleDisplayChanged}
          onOpenTaskImportModal={handleShowImportTaskModal}
        />
        <div id="pulse-timeline-gantt" className={clsx(styles['gantt-chart__content'], rollup && 'is-rollup')}>
          <BryntumGantt height={ganttHeight} ref={ganttRef} {...ganttConfig} />
        </div>
        <PulseTimelineGanttTaskDeleteModal
          deleteRecords={deleteRecords}
          onDeleteItems={handleDeleteTask}
          isOpen={showDeleteModal}
          onClose={handleCloseTaskDeleteModal}
        />
        <OverrideBaselineModal
          baseline={selectedBaseline || 0}
          isOpen={!!selectedBaseline}
          onCancel={() => setSelectedBaselines(0)}
          onConfirm={() => handleSetBaseline(selectedBaseline || 0, true)}
        />
        <NoBaselineModal isOpen={showNoBaselineModal} onClose={() => setShowNoBaselineModal(false)} />
        {showNonWorkingDaysModal && (
          <NonWorkingDaysModal
            isWeekendWorking={weekendWorkingRef.current}
            onSave={handleSaveNonWorkingItems}
            onCancel={() => setShowNonWorkingDaysModal(false)}
            timelineId={timeline?.id}
          />
        )}
        <ImportProjectFileModal
          isOpen={showFileImporterModal}
          projectId={project?.jobid}
          actions={{
            loadUrl: loadUrl,
            syncUrl: syncUrl,
          }}
          showMock={showMock}
          onCancel={() => setShowFileImporterModal(false)}
          onImportData={handleImportData}
          onHandleImportData={onHandleImportData}
        />
        {openTaskImportModal && (
          <PulseTaskImportModal
            onCloseModal={() => setOpenTaskImportModal(false)}
            project={project}
            timeline={timeline}
            onImported={handleOnImportedTimelineTask}
          />
        )}
      </div>
    </>
  );
};

export default PulseTimelineGantt;
